export default () => ({
  company: {
    companyIdentification: '',
    companyIdentificationCheck: '',
    companyName: '',
    identificationTypeId: '31',
    identificationTypeCode: 'NIT',
    identificationTypeName: 'NIT',
    companyStudentCardExpiration: '',
    companyStudentInstitutionCode: '',
    companyStudentInstitutionName: ''
  },
  email: '',
  firstName: '',
  fullName: '',
  ipAddress: '',
  lastName: '',
  phone: '',
  seller: '',
  source: '',
  userName: '',
  studentCard: ''
})
