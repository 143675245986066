export default identificationNumber => {
  if (isNaN(identificationNumber)) {
    return ''
  }
  const arreglo = new Array(16)
  let dv = 0
  arreglo[1] = 3
  arreglo[2] = 7
  arreglo[3] = 13
  arreglo[4] = 17
  arreglo[5] = 19
  arreglo[6] = 23
  arreglo[7] = 29
  arreglo[8] = 37
  arreglo[9] = 41
  arreglo[10] = 43
  arreglo[11] = 47
  arreglo[12] = 53
  arreglo[13] = 59
  arreglo[14] = 67
  arreglo[15] = 71

  let x = 0
  let y = 0
  for (let i = 0; i < identificationNumber.toString().length; i++) {
    y = +identificationNumber.toString().substr(i, 1)
    x += y * arreglo[identificationNumber.toString().length - i]
  }
  y = x % 11
  if (y > 1) {
    dv = 11 - y
  } else {
    dv = y
  }

  return dv
}
