export default {
  fullName: [{ required: true, message: 'Debes ingresarlos, son obligatorios', trigger: 'blur' }],
  company: {
    type: 'object',
    required: true,
    options: { first: true },
    fields: {
      companyName: { required: true, message: 'Ingresa el nombre de tu empresa', trigger: 'blur' },
      identificationTypeId: { required: true, message: 'Debe seleccionar un tipo de identificación', trigger: ['blur', 'change'] },
      companyIdentification: { required: true, message: 'Ingresa documento de tu empresa', trigger: 'blur' },
    },
  },
  email: [{
    type: 'email', required: true, message: 'Ingresa tu e-mail', trigger: 'blur',
  }],
  phone: [{
    required: true, pattern: /^[0-9]*$/, message: 'Ingresa el teléfono, sin espacios, unicamente números', trigger: 'blur',
  }, {
    min: 10, max: 10, message: 'El teléfono debe contener 10 números', trigger: 'blur',
  }],
}
